<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Protospelling'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <DictionarySelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isDictionaryFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for dictionary set in user settings
            ({{ $store.getters.getDictionariesByIds($store.state.user.settings.dictionaries).map(dictionary => dictionary.acronym).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.dict.protospelling.add')" class="float-right" v-b-modal.modal-new-protospelling>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new protospelling
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-protospelling" title="New protospelling" ok-title="Save" @ok="saveProtospelling" size="lg">
            <ProtospellingNew />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" @click="editProtospelling" :additionalData="tableAdditionalData" />
          <LegamDataTablesClientSide v-else v-bind="$data" @click="openDetails" ref="PITable" :additionalDataFilter="tableAdditionalDataFilter" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 100px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import tdColSafeHTML from '@/components/tdColSafeHTML'
  import tdColAction from '@/components/tdColAction'
  import PageTitle from '@/components/PageTitle.vue'
  import tdColUUID from '@/components/tdColUUID.vue'
  import DictionarySelectPI from '@/components/Dictionary/DictionarySelectPI.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import ProtospellingNew from '@/components/ProtospellingNew.vue'
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'ProtoSpellingList',
    data(){
      return {
        columns: [
          { title: 'ID', field: 'id', sortable: true, tdComp: tdColUUID, searchable: true },
          { title: this.$t('Spelling'), field: 'spelling', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: this.$t('Dictionary'), field: 'dictionaryAcronym', sortable: true, tdComp: tdColSafeHTML, searchable: true },

          { title: 'Lemma', field: 'lemmaForm', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
            let retVal = []
            if (row.lemmaId) {
              retVal.push({
                text: row.lemmaForm,
                icon: 'book',
                id: row.id,
                to: {name: 'Lemma', params: {id: row.lemmaId}}
              })
            }
            return retVal
          } },
          { title: 'Article', field: 'articleLemmaForm', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
            let retVal = []
            if (row.articleId) {
              retVal.push({
                text: row.articleLemmaForm,
                icon: 'book',
                id: row.id,
                to: {name: 'Article', params: {id: row.articleId}}
              })
            }
            return retVal
          } },

          { title: 'Details', field: 'action', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              let retVal = [
                {text: null, icon: 'list-ul', id: row.id, to: {name: 'ProtospellingDetails', params: {id: row.id}}}
              ]
              return retVal
            }
          }
        ],
        columnsPI: [
          { title: 'Spelling', field: 'spelling', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Dictionary', field: 'dictionaryAcronym', sortable: true, tdComp: tdColSafeHTML, searchable: true },

          { title: 'Lemma', field: 'lemmaForm', sortable: true, searchable: true, tdComp: tdColSafeHTML, transformData: function(row){
            // let retVal = []
            // console.log(row.lemmaForm[1])
            // if (row.lemmaId) {
            //   retVal.push({
            //     text: row[1],
            //     icon: 'book',
            //     id: row.id,
            //     to: {name: 'Lemma', params: {id: row[0]}}
            //   })
            // }
            return row.lemmaForm[1]
          } },
          { title: 'Article', field: 'articleLemmaForm', sortable: true, searchable: true, tdComp: tdColSafeHTML, transformData: function(row){
            // let retVal = []
            // console.log(row.articleLemmaForm)
            // if (row.articleId) {
            //   retVal.push({
            //     text: row.articleLemmaForm[1],
            //     icon: 'book',
            //     id: row.id,
            //     to: {name: 'Article', params: {id: row.articleLemmaForm[0]}}
            //   })
            // }
            return row.articleLemmaForm[1]
          } },
        ],
        url: '/protospellings/datatables',
        key: 1,
        searchableByColumn: true,
        protospelling: null,
        config: config,
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      ProtospellingNew,
      DictionarySelectPI,
    },
    computed: {
      ...mapState({
        // protospelling: state => state.protospelling.protospelling,
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'dictionaries': this.userSettings.dictionaries
        }
      }
    },
    watch: {
      "protospelling"(){
        this.$forceUpdate()
        this.$bvModal.show('modal-edit-protospelling')
      },
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.dictionaries.length) {
          data = data.filter(row => this.userSettingsPI.dictionaries.includes(row.dictionaryAcronym))
        }
        return data
      },
      editProtospelling(protospellingId){
        this.$store.dispatch('loadProtospelling', {
            protospellingId: protospellingId
          })
      },
      openDetails(data) {
        router.push(`/protospelling/${data.id}`)
      },
      saveProtospelling(){
        this.$store.dispatch('createProtospelling', HelpStore.item).then((response) => {
          router.push(`/protospelling/${response.data.id}`)
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Protospelling created`
          })
        })
      }
    }
  }
</script>
