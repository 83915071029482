<template>
  <div>
    <b-form-group
      id="spelling-group"
      label="Spelling"
      label-for="spelling"
    >
      <b-form-input
        id="spelling"
        v-model="model.spelling"
        placeholder="Spelling"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="comment-group"
      label="Comment"
      label-for="comment"
    >
      <b-form-textarea
        id="comment"
        placeholder="Comment"
        v-model="model.comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'ProtospellingNew',
    props: ['protospelling'],
    data(){
      let _model = (this.protospelling ? this.protospelling : {
        id: null,
        spelling: '',
        comment: ''
      })
      HelpStore.item = _model
      return {
        model: _model
      }
    },
    updated(){
      HelpStore.item = this.model
    }
  }
</script>
